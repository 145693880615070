import { ComponentSingleStyleConfig, CSSObject } from '@chakra-ui/react'
import {
  mode,
  StyleFunctionProps,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'
import { Dict } from '@chakra-ui/utils'
import '@fontsource/roboto/500.css'

// Following styling patterns outlined at
// https://chakra-ui.com/docs/styled-system/theming/component-style#styling-single-part-components

const sizes: Record<string, SystemStyleObject> = {
  md: {
    h: '2.25rem', // 36px
    minW: '8.5rem', // 136px
    fontSize: 'sm', // 14px
  },
  auto: {
    h: { base: '2.25rem', lg: '2.625rem' },
    minW: { base: '6.875rem', lg: '8.5rem' },
  },
  custom: {},
}

const baseStyle: SystemStyleObject = {
  borderRadius: 'base', // 4px
  textTransform: 'uppercase',
  letterSpacing: '0.01em',
  fontWeight: 'medium',
  lineHeight: '6', // 1.5rem → 24
  fontSize: 'sm', // 14px
  px: 4,
}

const solidDesign = (props: Dict | StyleFunctionProps): CSSObject => {
  const disabledStyle = {
    bg: mode('silverChalice', 'lightGrey')(props),
    color: mode('lightWhite', 'grey')(props),
    opacity: 1,
  }

  return {
    ...baseStyle,
    ...(props.size && sizes[props.size]),
    bg: mode('midBlue', 'lightBlue')(props),
    color: 'ivtBlue',
    _active: {
      bg: mode('ivtBlue', 'white')(props),
      color: mode('white', 'ivtBlue')(props),
    },
    _disabled: disabledStyle,
    _hover: {
      bg: mode('midBlue', 'lightBlue')(props),
      _disabled: disabledStyle,
    },
  }
}

const outlineDesign = (props: Dict | StyleFunctionProps): CSSObject => ({
  ...baseStyle,
  ...(props.size && sizes[props.size]),
  borderWidth: '0.125rem',
  borderColor: mode('ivtBlue', 'lightBlue')(props),
  color: mode('bgDark', 'white')(props),
  _active: {
    bg: mode('concord', 'glass.20')(props),
  },
  _disabled: {
    borderColor: 'lightGrey',
    color: 'lightGrey',
    opacity: 1,
  },
  _hover: {
    bg: 'transparent',
  },
})

const Button: ComponentSingleStyleConfig = {
  variants: {
    'ivt-solid': (props) => solidDesign(props),
    'ivt-outline': (props) => outlineDesign(props),
    'ivt-solid-rounded': (props) => ({
      ...baseStyle,
      ...solidDesign(props),
      borderRadius: 'full',
    }),
    'ivt-outline-rounded': (props) => ({
      ...baseStyle,
      ...outlineDesign(props),
      borderRadius: 'full',
    }),
  },
}

export default Button
