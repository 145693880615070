// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Set, PrivateSet } from '@redwoodjs/router'

import HostLayout from 'src/layouts/HostLayout'

import { useAuth } from './auth'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Set wrap={HostLayout}>
        <Route path="/login" page={LoginPage} name="login" />
        <Route path="/i/{urlName}/exit" page={InterviewExitPage} name="interviewExit" />
        <Route path="/i/{urlName}/send" page={InterviewExitPage} name="interviewExitSend" />
        <PrivateSet unauthenticated="login">
          <Route path="/" page={HomePage} name="home" />
        </PrivateSet>
      </Set>
      <PrivateSet unauthenticated="login" wrap={HostLayout} inRecordings>
        <Route path="/i/{urlName}/download" page={InterviewRecordingsPage} name="interviewRecordings" />
      </PrivateSet>
      <Set wrap={HostLayout} inSession>
        {/* TODO: Change path logic to something like /i/{title}/{interviewId}/{tokenId} */}
        <Route path="/i/{urlName}" page={InterviewEntrancePage} name="interviewEntrance" />
        <Route path="/i/{urlName}/session" page={InterviewSessionPage} name="interviewSession" />
      </Set>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
