import type { MouseEvent } from 'react'

import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import { HTMLChakraProps, IconProps } from '@chakra-ui/react'
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  chakra,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'

import {
  IoBookOutline,
  IoDocumentTextOutline,
  IoHelpCircleOutline,
  IoPerson,
  IoPower,
} from 'src/components/Icons'

const menuIconStyle: IconProps = {
  boxSize: 6,
  mr: 4,
}

interface ProfileProps extends HTMLChakraProps<'button'> {
  userDisplayName: string
  logoutText: string
  faqText?: string
  manualText?: string
  releaseNotesText?: string
  darkModeText: string
  lightModeText: string
  showColorModeToggle: boolean
  isSmall: boolean
  onLogoutClick: (event: MouseEvent<HTMLButtonElement>) => Promise<unknown>
}

const Profile = ({
  userDisplayName,
  logoutText = 'Logout',
  faqText = 'FAQ',
  manualText = 'Manual',
  releaseNotesText = 'Release notes',
  darkModeText = 'Switch to dark mode',
  lightModeText = 'Switch to light mode',
  showColorModeToggle = false,
  isSmall = false,
  ...props
}: ProfileProps) => {
  const SwitchIcon = useColorModeValue(MoonIcon, SunIcon)
  const switchModeText = useColorModeValue(darkModeText, lightModeText)
  const { toggleColorMode } = useColorMode()

  const menuLinks = [
    { url: process.env.FAQ_URL, text: faqText, icon: IoHelpCircleOutline },
    { url: process.env.MANUAL_URL, text: manualText, icon: IoBookOutline },
    {
      url: process.env.RELEASE_NOTES_URL,
      text: releaseNotesText,
      icon: IoDocumentTextOutline,
    },
  ]

  return (
    <chakra.div>
      {/** ^ required to work around chakra UI bug https://github.com/chakra-ui/chakra-ui/issues/3440 */}
      <Menu {...props} variant="ivt-profile" isLazy>
        <MenuButton
          title={props.title ?? userDisplayName}
          aria-label="User"
          as={Button}
          variant="ivt-outline-rounded"
          size="custom"
          {...(isSmall
            ? { height: 9, width: '3.75rem' }
            : { height: [9, '2.625rem'], width: ['3.75rem', 20] })}
        >
          <Icon boxSize="6" as={IoPerson} verticalAlign="middle" />
        </MenuButton>
        <MenuList zIndex="popover">
          <MenuGroup title={userDisplayName}>
            {showColorModeToggle && (
              <MenuItem onClick={toggleColorMode} closeOnSelect={false}>
                <Icon as={SwitchIcon} {...menuIconStyle} />
                {switchModeText}
              </MenuItem>
            )}
            {menuLinks.map(
              ({ url, text, icon }) =>
                url && (
                  <MenuItem
                    as="a"
                    href={url}
                    target="_blank"
                    key={text}
                    closeOnSelect={false}
                  >
                    <Icon as={icon} {...menuIconStyle} />
                    {text}
                  </MenuItem>
                )
            )}
            <MenuItem onClick={props.onLogoutClick}>
              <Icon as={IoPower} {...menuIconStyle} />
              {logoutText}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </chakra.div>
  )
}

export default Profile
